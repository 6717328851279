import { Helmet } from 'react-helmet-async';
import { filter, isArray } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useRef } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import axios from 'axios'
import EditAthletePopup from 'src/components/stComponents/EditAthletePopup';
import SteLib from 'src/lib/stelib';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'memberId', label: '# Membro', alignRight: false },
  { id: 'expiryDate', label: 'Scadenza', alignRight: false },
  { id: 'birthDate', label: 'Data di nascita', alignRight: false },
  { id: 'memberType', label: 'Tipo', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {

  const [firstTime, setFirstTime] = useState(true);

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('memberId');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [members, setMembers] = useState([])

  const [popoverId, setPopoverId] = useState(undefined)
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState(undefined);
  
  let editPopupRef = undefined;

  useEffect(() => {
    if(firstTime)
    {
      getMembers();
    }
    setFirstTime(false);
  });
  

  const handleOpenMenu = (event, id) => {
    setPopoverId(id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = members.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const getMemberType = (type) => {
    return type === 0 ? "Non Pro" : "Pro";
  }

  const getMembers = () => {
    SteLib.GetRequest("members", {}, {}).then((data) => {
      if(isArray(data))
      {
        data.map(d => {
          if(!isNaN(d.memberId))
          {
            d.memberId = parseInt(d.memberId);
          }
        });
      }
      setMembers(data)
    });
  }

  //getUser();

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - members.length) : 0;

  const filteredUsers = applySortFilter(members, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  let openEditPopup = () => {
    if(popoverId > 0)
    {
      SteLib.GetRequest("members/"+popoverId, {}, {}).then((data) => {
        if(data !== undefined)
        {
          setOpenEdit(true);
          setEditData(data);
          handleCloseMenu();
        }
      });
    }
  }

  let deleteMember = () => {
    if(popoverId > 0)
    {
      if(window.confirm("Sei sicuro?"))
      {
        SteLib.DeleteRequest("members/"+popoverId, {}, false).then((data) => {
          handleCloseMenu();
          getMembers();
        });
      }
    }
  }

  let getCard = () => {
    if(popoverId > 0)
    {
      SteLib.GetRequest("members/"+popoverId+"/card", {}, false).then((data) => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + data.image; //Image Base64 Goes here
        a.download = "Tessera_"+String(data.member.name) + String(data.member.surname); //File name Here
        a.click(); //Downloaded file
        handleCloseMenu();
      });
    }
  }

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Dashboard
          </Typography>
          <EditAthletePopup setOpenEdit={setOpenEdit} openEdit={openEdit} editData={editData} setEditData={setEditData} successCallback={getMembers}/>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={members.length}
                  //numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  //onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, memberId, expiryDate, birthDate, avatarUrl, memberType } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        {<>&nbsp;</>/*<TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                        </TableCell>*/}

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/*<Avatar alt={name} src={avatarUrl} />*/}
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{memberId}</TableCell>

                        <TableCell align="left">{SteLib.FormatDMY(expiryDate)}</TableCell>

                        <TableCell align="left">{SteLib.FormatDMY(birthDate)}</TableCell>

                        <TableCell align="left">
                          <Label color={(memberType === 0 && 'error') || 'success'}>{getMemberType(memberType)}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Non trovato
                          </Typography>

                          <Typography variant="body2">
                            Nessun risultato trovato per &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Prova cercando parti di testo o parole complete.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={members.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage='Righe per pagina:'
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count}`}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      
      

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={openEditPopup}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Modifica
        </MenuItem>

        <MenuItem onClick={deleteMember} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Elimina
        </MenuItem>

        <MenuItem onClick={getCard} sx={{ color: '#00496f' }}>
          <Iconify icon={'eva:credit-card-fill'} sx={{ mr: 2 }} />
          Tessera
        </MenuItem>
      </Popover>
    </>
  );
}
