import fetch from '../auth/FetchInterceptor'
import { TIMEOUT } from '../auth/FetchInterceptor'
import { useState } from 'react';

export const ERROR_DURATION = 3
export const SUCCESS_DURATION = 4
const key = 'updatable';

/*const [alert, setAlert] = useState({
	open: true,
	message: ""
})*/

class SteLib {

	static currentAppLocale;
	static intl;
	static menuInstance;

	static history;
	
	static alert = {
		open: false
	};

	/*static setCurrentAppLocale(locale)
	{
		SteLib.currentAppLocale = locale;
		SteLib.intl = createIntl({
			locale: SteLib.currentAppLocale.locale,
			messages: SteLib.currentAppLocale.messages,
		}, cache)
	}

	static GetShortDateFormat()
	{
		return (SamacLib.intl.formatMessage({id: "system.format.shortdate"}));
	}

	static GetDateFormat()
	{
		return (SamacLib.intl.formatMessage({id: "system.format.date"}));
	}

	static GetTimeFormat()
	{
		return (SamacLib.intl.formatMessage({id: "system.format.time"}));
	}

	static GetDateTimeFormat()
	{
		return (SamacLib.intl.formatMessage({id: "system.format.dateTime"}));
	}

	static GetLanguage(key)
	{
		return (SamacLib.intl.formatMessage({id: key}));
	}*/

	static FormatDMY(date) {
		let d = Date.parse(date);
		if(!isNaN(d))
		{
			d = new Date(d);
      		return String(d.getDate()).padStart(2,'0')+'/'+String(d.getMonth() + 1).padStart(2,'0')+'/'+d.getFullYear();
		}
		return "";
	}

	static GetRequest(url, params) {
		return new Promise(function(resolve, reject)
		{
			fetch(url, {method: 'GET', timeout: TIMEOUT, params: params})
			.then(body => {
			   if(body !== undefined)
			   {
				  resolve(body.data.data);
			   }
			})
			.catch((err) => {
				reject(err);
			})
		})
	}

	static DownloadPng(url){
		fetch(url, {method: 'GET', timeout: TIMEOUT, params: {}, responseType: "arraybuffer"})
		.then(response => {
			alert("Here")
			console.log(response);
			let blob = new Blob(
				[response.data], 
				{ type: response.headers['content-type'] }
			)
			let image = URL.createObjectURL(blob)
			return image
			})
	}

	static DownloadFileRequest(url, params) {
		return new Promise(function(resolve, reject)
		{
			fetch(url, {method: 'GET', timeout: TIMEOUT, params: params, responseType: "blob"})
			.then(body => 
			{
				var blob = body.data;
				var filename = "";
				var disposition = body.headers['content-disposition'];
				if (disposition && disposition.indexOf('attachment') !== -1) {
					var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					var matches = filenameRegex.exec(disposition);
					if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
				}

				if (typeof window.navigator.msSaveBlob !== 'undefined') {
					// IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
					window.navigator.msSaveBlob(blob, filename);
				} else {
					var URL = window.URL || window.webkitURL;
					var downloadUrl = URL.createObjectURL(blob);

					if (filename) {
						// use HTML5 a[download] attribute to specify filename
						var a = document.createElement("a");
						// safari doesn't support this yet
						if (typeof a.download === 'undefined') {
							window.location.href = downloadUrl;
						} else {
							a.href = downloadUrl;
							a.download = filename;
							document.body.appendChild(a);
							a.click();
						}
					} else {
						window.location.href = downloadUrl;
					}

					setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
				}
			});
        });
	}

	/*static DownloadFileRequest2(url, params) {
		return new Promise(function(resolve, reject)
		{
			fetch(url, {method: 'GET', timeout: TIMEOUT, params: params})
			.then(body => {
				let fileName = "REPORT.pdf"
			   	let disposition = body.headers['content-disposition'];
				if (disposition) {
					var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					var matches = filenameRegex.exec(disposition);
					fileName = matches[0].replace('filename=','')
				}
			   	if(body !== undefined)
			   	{
				  resolve({file: body.data, fileName: fileName});
			  	}
			})
			.catch((err) => {
				reject(err);
			})
		})
	}*/

	static PostRequest(url, data, silent) {
		return new Promise(function(resolve, reject)
		{
			silent = (silent === true) ? true : false

			if(!silent)
			{
				//TODO
				//message.loading({ content: 'Aggiornamento dati...', key, duration: TIMEOUT/1000 });
			}
			fetch(url, {method: 'POST', data, timeout: TIMEOUT})
			.then((data) => {
				if(!silent)
				{
					SteLib.HandleResponse(data.data);
				}
				resolve(data.data.data);
			})
			.catch((err) => {
				reject(err);
			})
			
		})
	}

	static PutRequest(url, data, overrideHandle = false) {
		return new Promise(function(resolve, reject)
		{
			//TODO
			//message.loading({ content: 'Aggiornamento dati...', key, duration: overrideHandle ? 1 : TIMEOUT/1000 });
			fetch(url, {method: 'PUT', data, timeout: TIMEOUT})
			.then((data) => {
				if(!overrideHandle)
				{
					SteLib.HandleResponse(data.data);
				}
				resolve(data.data.data);
			})
			.catch((err) => {
				reject(err);
			})
			
		})
	}

	static DeleteRequest(url, data, overrideHandle = false) {
		return new Promise(function(resolve, reject)
		{
			//TODO
			//message.loading({ content: 'Aggiornamento dati...', key, duration: overrideHandle ? 1 : TIMEOUT/1000 });
			fetch(url, {method: 'DELETE', data, timeout: TIMEOUT})
			.then((data) => {
				if(!overrideHandle)
				{
					SteLib.HandleResponse(data.data);
				}
				resolve(data.data.data);
			})
			.catch((err) => {
				reject(err);
			})
			
		})
	}

	/*static FileRequest(url, params, fileType = "PDF") {
		return new Promise(function(resolve, reject)
		{
			let contentType = "application/pdf"
			switch(fileType)
			{
				default:
				case "PDF": contentType = "application/pdf"; break;
				case "EXCEL": contentType = "application/vnd.ms-excel"; break;
			}

			fetch(url, {method: 'GET', params: params, timeout: TIMEOUT, responseType: "arraybuffer"})
			.then((blob) => {
				const file = new Blob([blob.data], { type: contentType });

				//file.ContentDisposition = "inline; filename=REPORT";

				const fileURL = URL.createObjectURL(file, "");
				
				resolve(fileURL);
			})
			.catch((err) => {
				reject(err);
			})
			
		})
	}

	static FileRequestDownload(url, params, fileType = "PDF", fileName = "download") {
		return new Promise(function(resolve, reject)
		{
			let contentType = "application/pdf"
			let extension = ".pdf"
			switch(fileType)
			{
				default:
				case "PDF": 
					contentType = "application/pdf"; 
					extension = ".pdf";
					break;
				case "EXCEL": 
					contentType = "application/vnd.ms-excel"; 
					extension = ".xlsx";
					break;
			}

			fetch(url, {method: 'GET', params: params, timeout: TIMEOUT, responseType: "arraybuffer"})
			.then((blob) => {
				const file = new Blob([blob.data], { type: contentType });

				SamacLib.saveFile(file, fileName + extension);
				resolve();
			})
			.catch((err) => {
				reject(err);
			})
			
		})
	}

	static saveFile(blob, filename) {
		if (window.navigator.msSaveOrOpenBlob) {
		  window.navigator.msSaveOrOpenBlob(blob, filename);
		} else {
		  const a = document.createElement('a');
		  document.body.appendChild(a);
		  const url = window.URL.createObjectURL(blob);
		  a.href = url;
		  a.download = filename;
		  a.click();
		  setTimeout(() => {
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		  }, 0)
		}
	  }*/

	static HandleResponse(data)
	{
		
		let resp = {
			status: 0, //0: unknown, 1: success, 2: error, 3: warning
			message: "errGeneric"
		}

		console.log(data)

		if(data?.backStatus !== undefined && data?.message !== undefined)
		{
			resp.status = data.backStatus;
			resp.message = data.message;
		}

		let html = resp.message;//SamacLib.GetLanguage(`backendMsg.${resp.message}`);
		let message = "N.D.";
		let severity = "info";

		switch(resp.status)
		{
			default:
				message = "N.D.";
				break;
			case 1:
				message = "Aggiornato con successo.";
				severity = "success";
				break;
			case 2:
				message = "Errore generico.";
				severity = "error";
				break;
			case 3:
				message = "Aggiornato 2";
				severity = "success";
				break;
		}

		SteLib.setAlert({...SteLib.alert, open:true, message: message, severity: severity});
	}
}

export default SteLib;