import * as React from 'react';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Container } from '@mui/system';
import Iconify from '../iconify';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Dayjs } from 'dayjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import SteLib from 'src/lib/stelib';
import 'dayjs/locale/it';


export default function EditAthletePopup({setOpenEdit, openEdit, setEditData, editData, successCallback}) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [expiryDate, setExpiryDate] = React.useState({});
  const [birthDate, setBirthDate] = React.useState({});

  const initData = {
    name: undefined,
    surname: undefined,
    memberId: undefined,
    memberType: undefined,
    expiryDate: null,
    birthDate: null
  }

  const [formData, setFormData] = React.useState(initData);

  const [formSubmitted, setFormSubmitted] = React.useState(false);

  useEffect(() => {
    if(openEdit === true)
    {
      handleEditOpen();
      if(editData !== undefined)
      {
        setFormData(editData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEdit])

  const handleEditOpen = () => {
    setOpenEdit(false);
    handleClickOpen();
    setOpen(true);
  };
  
  const handleClickOpen = () => {
    setFormData(initData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData(undefined);
    setFormSubmitted(false);
  };

  const handleSave = () => {
    setFormSubmitted(true);
    if(!fieldNotValid(formData.name, true) &&
       !fieldNotValid(formData.surname, true) &&
       !fieldNotValid(formData.memberId, true) &&
       !fieldNotValid(formData.expiryDate, true) &&
       !fieldNotValid(formData.memberType, true) &&
       !fieldNotValid(formData.birthDate, true) &&
       !dateNotValid(formData.birthDate) &&
       !dateNotValid(formData.expiryDate)
    )
    {
      let d = new Date(Date.parse(formData.expiryDate));
      formData.expiryDate = d.getFullYear()+'-'+(d.getMonth() + 1)+'-'+d.getDate();
      d = new Date(Date.parse(formData.birthDate));
      formData.birthDate = d.getFullYear()+'-'+(d.getMonth() + 1)+'-'+d.getDate();
      
      let closeAndRefresh = false;
      //PUT
      if(editData !== undefined)
      {
        SteLib.PutRequest("members/"+formData.id, formData, false).then((data) => {
          handleClose();
          successCallback();
        });
      }
      else //POST
      {
        SteLib.PostRequest("members/", formData, false).then((data) => {
          handleClose();
          successCallback();
        });
      }
    }
  };

  let fieldNotValid = (field, ignoreFormSubmitted = false) => {
    let fSub = ignoreFormSubmitted ? true : formSubmitted;
    return (field === "" || field === undefined || field === null) && fSub;
  }

  let dateNotValid = (date) => {
    if (typeof date === 'string')
    {
      date = Date.parse(date);
    }
    return isNaN(date);
  }

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
        Nuovo Membro
      </Button>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
        <DialogTitle>Nuovo Membro</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Inserisci i dati della persona
          </DialogContentText>
          <Stack spacing={3}>
              <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Nome"
                  type="text"
                  error={fieldNotValid(formData.name)}
                  value={formData.name}
                  onChange={(newValue) => {
                    setFormData({...formData, name: newValue.target.value})
                  }}
                  variant="standard"
              />
              <TextField
                  margin="dense"
                  id="surname"
                  label="Cognome"
                  type="text"
                  error={fieldNotValid(formData.surname)}
                  value={formData.surname}
                  onChange={(newValue) => {
                    setFormData({...formData, surname: newValue.target.value})
                  }}
                  variant="standard"
              />
              <TextField
                  margin="dense"
                  id="memberId"
                  label="Id Membro"
                  type="text"
                  error={fieldNotValid(formData.memberId)}
                  value={formData.memberId}
                  onChange={(newValue) => {
                    setFormData({...formData, memberId: newValue.target.value})
                  }}
                  variant="standard"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                <DatePicker
                  views={['day', 'month', 'year']}
                  label="Data di nascita"
                  value={formData.birthDate}
                  onChange={(newValue) => {
                    setFormData({...formData, birthDate: newValue})
                  }}
                  renderInput={(params) => <TextField variant='standard' {...params} 
                  error={fieldNotValid(formData.birthDate) || dateNotValid(formData.birthDate)} 
                  />}
                />
              </LocalizationProvider>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Tipo Membro</InputLabel>
                <Select
                  id="memberType"
                  value={formData.memberType}
                  error={fieldNotValid(formData.memberType)}
                  onChange={(newValue) => {
                    setFormData({...formData, memberType: newValue.target.value})
                  }}
                >
                  <MenuItem value={0}>Non Pro</MenuItem>
                  <MenuItem value={1}>Pro</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                <DatePicker
                  views={['day', 'month', 'year']}
                  label="Data Scadenza"
                  value={formData.expiryDate}
                  onChange={(newValue) => {
                    setFormData({...formData, expiryDate: newValue})
                  }}
                  renderInput={(params) => <TextField variant='standard' {...params} 
                  error={fieldNotValid(formData.expiryDate)|| dateNotValid(formData.expiryDate)} />}
                />
              </LocalizationProvider>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annulla</Button>
          <Button onClick={handleSave}>Salva</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}