import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../configs/AppConfig'
import { AUTH_TOKEN, NAME, SURNAME } from '../../../auth/const'

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import axios from 'axios';

// ----------------------------------------------------------------------

export default function LoginForm({setToken}) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const handleClick = () => {
    setLoginError(false);
    axios.post(API_BASE_URL + 'login', {email: formData.email, password: formData.password})
    .then(response => {
      let data = response.data;
      if(data !== undefined)
      {
        setToken(data.jwt);
        localStorage.setItem(AUTH_TOKEN, data.jwt);
        localStorage.setItem(NAME, data.name);
        localStorage.setItem(SURNAME, data.surname);
        setTimeout(() => {
          return navigate(`${process.env.PUBLIC_URL}/dashboard`, { replace: true });
        }, 400);
      }
    }).catch(exc => {
      setLoginError(true);
    });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField 
          onKeyDown={(event) => {if(event.key === "Enter"){handleClick()}}}
        value={formData.email} onChange={(newValue) => {
          setFormData({...formData, email: newValue.target.value});
        }} name="email" label="Indirizzo email" />

        <TextField
          name="password"
          label="Password"
          onKeyDown={(event) => {if(event.key === "Enter"){handleClick()}}}
          value={formData.password} onChange={(newValue) => {
            setFormData({...formData, password: newValue.target.value});
          }} 
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {loginError && <div style={{textAlign: "center", marginBottom: "-1.5rem"}}>
        <span style={{color: "red"}}>Username o password errati</span>
        </div>}
      </Stack>

      {/*<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
        </Stack>*/}
      
      <br/><br/>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
