import axios from 'axios'
import { API_BASE_URL } from '../configs/AppConfig'
import history from '../history'
import { AUTH_TOKEN, NAME, SURNAME } from './const'
//import { notification, message } from 'antd';
//import SamacLib from '../lib/samac'
export const TIMEOUT = 60000;

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: TIMEOUT
})

// Config
const ENTRY_ROUTE = '/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
export const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {

  const jwtToken = localStorage.getItem(AUTH_TOKEN)

  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	config.headers['content-type'] = "application/json"
  }

  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
	  //alert(config.headers[PUBLIC_REQUEST_KEY])
		history.push(ENTRY_ROUTE)
		window.location.reload();
  }

  return config
}, error => {
	// Do something with request error here
	alert("Error")
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response
}, (error) => {
	
	let notificationParam = {
		message: 'Generic Error'
	}
	
	if (error?.response?.status === 400) {
		if(error.response.data?.title !== undefined && String(error.response.data.title).toUpperCase().indexOf("VALIDATION") >= 0)
		{
			notificationParam.message = "Errore Dati:";
			notificationParam.description = []
			for (const [key, value] of Object.entries(error.response.data.errors)) {
				notificationParam.description.push(<div>{key}: {value}</div>);
			}
		}
	}

	// Remove token and redirect 
	if (error?.response?.status === 401 || error?.response?.status === 403) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN);
		localStorage.removeItem(NAME);
		localStorage.removeItem(SURNAME);

		history.push(ENTRY_ROUTE)
		window.location.reload();
	}

	if (error?.response?.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error?.response?.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}
	
	if (error?.response?.status === 508) {
		notificationParam.message = 'Time Out'
	}

    alert("Error");

	return Promise.reject(error);
});

export default service