import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import React from 'react'
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SteLib from 'src/lib/stelib';
import './DashboardLayout.css'
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({setToken}) {
  const [open, setOpen] = useState(false);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    [SteLib.alert, SteLib.setAlert] = useState({
      open: false,
      message: "",
      severity: "info"
    })
  
    const [state, setState] = React.useState({
      vertical: 'top',
      horizontal: 'center',
    });
    const { vertical, horizontal, snackOpen } = state;

    const handleClose = () => {
      SteLib.setAlert({...SteLib.alert, open:false});
    };
  
  return (
    <StyledRoot>

      
      <Header setToken={setToken} onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      
      <Main>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={SteLib.alert.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert  onClose={handleClose} severity={SteLib.alert.severity} sx={{ color: "white", width: '100%' }}>
          {SteLib.alert.message}
        </Alert>
      </Snackbar>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
