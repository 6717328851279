import { Route, Navigate, useRoutes, Routes } from 'react-router-dom';
// layouts
import {useState} from 'react';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { AUTH_TOKEN } from './auth/const'

// ----------------------------------------------------------------------


export default function Router() {
  let [token, setToken] = useState(localStorage.getItem(AUTH_TOKEN));

  return (
    <Routes>
        {(token !== '' && token !== null && token !== undefined) && 
        <>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />}></Route> :
          <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<DashboardLayout setToken={setToken} />}>
            <Route path={`${process.env.PUBLIC_URL}/dashboard`} element={<DashboardAppPage />} index={true}></Route>
          </Route>
        </>}

        {(token === '' || token === null || token === undefined) ? 
          <>
            <Route path={`${process.env.PUBLIC_URL}/login`} element={<LoginPage setToken={setToken} />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/*`} element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />}></Route>
          </> :
          <>
            <Route path={`${process.env.PUBLIC_URL}/*`} element={<Navigate to={`${process.env.PUBLIC_URL}/404`} />}></Route>
            <Route path={`${process.env.PUBLIC_URL}/login`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard`} />}></Route>
          </>
        }
        <Route path={`${process.env.PUBLIC_URL}/404`} element={<Page404 />}></Route>
    </Routes>)
}

/*
export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}*/
